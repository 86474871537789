<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
          :src="require('@/assets/images/logo/logo.png')"
          alt="logo"
      />

      <h2 class="brand-text text-primary ml-1">
        ERP
        <small>
          <i>by HMT</i>
        </small>
      </h2>

    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('PageNotFound') }} 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          {{ $t('Oops!') }} 😖 {{ $t('The requested URL was not found on this server') }}.
        </p>

        <b-button
            variant="primary"
            class="mb-2 btn-sm-block"
            :to="{path:'/'}"
        >
          {{$t("BackToHome")}}
        </b-button>

        <!-- image -->
        <b-img
            fluid
            :src="require('@/assets/images/pages/error.svg')"
            alt="Error page"
        />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>

export default {
  components: {},
  data () {
    return {}
  },
  computed: {},
}
</script>

<style lang="scss">
@import "~@/assets/scss/base/pages/misc.scss";
</style>
